html {
	scroll-behavior: smooth;
}
 
* {
	margin: 0;
	padding: 0;
	outline: 0;
	box-sizing: border-box;
}
 
a, a:hover {
	text-decoration: none;
}
 
h1, h2,h3, h4, h5, h6 ,p {
	margin-bottom: 0;
	margin-top: 0;
}
 
ul li {
	list-style: none;
}

img {
	max-width: 100%;
	height: auto;
}


body {
	font-family: 'Nunito Sans', sans-serif;
	/* max-width: 960px; */
	margin: auto;
	background: #f1f1f1;
}
/* ===//end of basic css === */

/* ================== main css start ================= */

/*=== advertisement part start ===*/
.advertisement  {
    box-shadow: 0px 5px 12.09px 0.91px rgba(0, 0, 0, 0.29) !important;
    position: relative;
    z-index: 222;
    height: 200px;
    background: url(../images/banner.png) no-repeat center center/cover;
}
/*===//end of advertisement part ===*/

/* === header css start === */
.header1 {
	width: 100%;
	position: sticky;
	top: 0;
	z-index: 999;
	box-shadow: 0 0 10px rgba(0,0,0,.1);
}

.header1 .home-btn {
	color: rgba(0,0,0,.5);
	border: 1px solid rgba(0, 0, 0, .1);
	padding: .5rem .75rem;
	font-size: 1.25rem;
	line-height: 1;
	display: block;
	border-radius: .25rem;
	cursor: pointer;
	margin-right: 15px;
}

.header1 .home-btn:hover {
	color: #0090E3;
}

.navbar-light .navbar-toggler {
	position: relative;
	z-index: 9999;
}


.navbar-brand {
	max-width: 180px;
}

.header1 .navbar-light .navbar-nav .nav-link {
	text-align: center;
	color: #333;
	border-bottom: 1px solid rgba(0, 0, 0, .1);
	padding-left: 5px;
}

.header1 .navbar-light .navbar-nav .nav-link:hover {
	color: #0090E3;
}

.header1 .container {
	background: transparent
}
/* ===//end of header css === */


.main-content-bg {
	background: url(../images/contact/main-content-bg.jpg) no-repeat center center/cover;
	padding: 1px 0;
}

/* .main-content-bg1 {
    background: url(../images/score-setting/mian-content-bg.png) no-repeat center center/cover;
    padding-bottom: 1px;
} */

/* === contact css start === */
.contact .container {
	margin: 60px auto;
	background: #333;
	border-radius: 25px;
	box-shadow: 0 9px 14px 1.2px rgba(0, 0, 0, 0.38);
	padding: 30px;
}

.contact h2 {
	color: #fff;
	font-size: 35px;
	font-weight: 700;
	text-align: center;
	padding-bottom: 20px;
}

.contact .required-text {
	color: #fff;
	font-size: .8rem;
	padding-bottom: 10px;
	display: block;
}

.contact .form-control {
	border-radius: 0;
	background: transparent;
	color: #fff;
	font-size: .8rem;
}

.form-control::placeholder {
	color: #fff;
}

.contact textarea.form-control {
	background: #fff;
	border-radius: 15px;
	color: #333;
	box-shadow: 0 9px 14px 1.2px rgba(0, 0, 0, 0.38);
	font-size: .9rem;
	margin: 10px 0 30px 0;
	resize: none;
}

.contact textarea::placeholder {
	color: #333;
}

.submit-btn .btn {
	background: #0587ef;
	border-radius: 25px;
	color: #fff;
	font-weight: 700;
	font-size: 16px;
	min-width: 130px;
	cursor: pointer;
	box-shadow: 0 9px 14px 1.2px rgba(0, 0, 0, 0.38);
}

.submit-btn .btn:hover {
	opacity: 0.8;
}

/* ===//end of contact part === */

/* === footer part start === */
.footer {
	background: #1f2223;
	min-height: 90px;
	text-align: center;
  }
  
  .footer a{
	font-size: 17px;
	color: #fff;
	display: block;
  }
  
  .footer .footer-link li{
	display: inline-block;
	padding-top: 15px;
  }
  /* ===//end of  footer part === */



/* responsive */

@media (max-width: 450px) {
	.header1 .home-btn,
	.navbar-toggler {
		font-size: 1rem;
	}

	.navbar-brand {
		width: 150px;
	}


}


@media (max-width: 767px) {
	.footer .footer-link  {
		padding-bottom: 15px;
	}
}
