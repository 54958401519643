/* === Pricacy-policy and Terms  css start === */
.how-works-faq .container {
	/* max-width: 90%; */
	margin: 60px auto;
	background: #333;
	border-radius: 25px;
	box-shadow: 0 9px 14px 1.2px rgba(0, 0, 0, 0.38);
	padding: 30px 20px 5px 20px;
}

.how-works-faq h2 {
	color: #fff;
	font-size: 35px;
	font-weight: 700;
	text-align: center;
	padding-bottom: 20px;
}

.how-works-faq .inner-box {
	background: #fff;
	border-radius: 10px;
	padding: 30px 15px;
	box-shadow: 0 9px 14px 1.2px rgba(0, 0, 0, 0.38);
	margin-bottom: 60px;
}

.inner-box.how-its-work {
	height: 230px;
	overflow-x: scroll;
	overflow-x: hidden;
}

.inner-box h3 {
	font-size: 1.3rem;
	font-weight: 700;
	text-align: center;
	color: #333;
}
.inner-box .ques{
	font-size: 1rem;
	font-weight: 600;
	color: #222;
	padding-bottom: 5px;
}


.inner-box .ans{
	font-size: 1rem;
	font-weight: 400;
	color: #353535;
	margin-bottom: 50px;
}


.how-works-faq li {
	padding: 5px 0;
}

.how-works-faq .faq span {
	display: inline-block;
	padding-right: 10px;
	font-weight: 600;
	font-size: 1.2;
}


/* ===//end of Pricacy-policy and Terms  part === */


/* === ? css start === */
/* ===//end of ? part === */
/* ===//end of main-section part === */


/* === footer section start === */
.footer {
    background: rgba(0, 0, 0, 0.9);
    padding: 10px 80px;
    color: #fff;
  }
  
  .footer ul li a {
    font-size: 14px;
    display: block;
    color: #fff;
    margin: 5px;
  }
  /* ===//end of footer section === */



/* ====== Responsive css ====== */

@media(min-width: 300px) and (max-width: 474px) {
	.footer {
	  padding: 10px 15px;
	}
}
@media(max-width: 575px) {
	.how-works-faq h2 {
		font-size: 25px;
	}

	.inner-box h3 {
		font-size: 1.1rem;
	}

	.inner-box p {
		font-size: .8rem;
	}
	
	.how-works-faq .container {
		max-width: 90%;
	}
}
