
/* === navbar start === */
.ancBtn{
    color: #0587ef;
    font-size: 20px;
    margin-top: 10px;
}

nav{
    background: #fff;
    box-shadow: 0px 5px 12.09px 0.91px rgba(0, 0, 0, 0.29) !important;
    padding: 30px 0px 20px 0px;
}

.menuBtn {
    color: #1f2122;
    font-size: 28px;
    margin-left: 20px;
    margin-top: 4px;
    padding: 0px 10px;
    cursor: pointer;
}

.pos_rel{
    position: relative;
}
/* Nav menu */
.navMenu {
    position: absolute;
    right: 15px;
    top: 73px;
    background: #1f2223;
    padding: 35px 30px 10px 30px;
    display: none;
}

.navMenu ul li a{
    font-size: 20px;
    color: #fff;
    margin: 10px 0;
    display: block;
}


.menuBtn_2 {
    color: #fff;
    font-size: 30px;
    position: relative;
    left: 0px;
    top: -30px;
    cursor: pointer;
}

.m::before {
    content: '';
    position: absolute;
    top: 18px;
    left: -14px;
    width: 114%;
    height: 1px;
    background: #6e6e6e;
}

.dropdown .nav-link {
    padding: 0;
}

.dropdown-menu {
    margin: 0;
    padding: 0;
    /* background: #000 */
}

.dropdown-item {
    padding: .25rem .5rem;
}

.dropdown .dropdown-menu a {
    margin: 0;
    font-size: 18px;
}


/* === Pricacy-policy and Terms  css start === */
.pricacy-term .container {
	margin: 60px auto;
	background: #333;
	border-radius: 25px;
	box-shadow: 0 9px 14px 1.2px rgba(0, 0, 0, 0.38);
	padding: 30px 20px 5px 20px;
}

.pricacy-term h2 {
	color: #fff;
	font-size: 35px;
	font-weight: 700;
	text-align: center;
	padding-bottom: 20px;
}

.pricacy-term .inner-box {
	background: #fff;
	border-radius: 10px;
	padding: 30px 15px;
	box-shadow: 0 9px 14px 1.2px rgba(0, 0, 0, 0.38);
	margin-bottom: 60px;
}

.inner-box h3 {
	font-size: 1.3rem;
	font-weight: 700;
	text-align: center;
	color: #333;
}

.inner-box p {
	font-size: 1rem;
	font-weight: 400;
	color: #353535;
	padding-bottom: 10px;
}

.pricacy-term .download-btn {
	background: #0587ef;
	border-radius: 25px;
	color: #fff;
	font-weight: 700;
	font-size: 16px;
	min-width: 130px;
	cursor: pointer;
	box-shadow: 0 9px 14px 1.2px rgba(0, 0, 0, 0.38);
}

.pricacy-term .download-btn:hover {
	opacity: 0.8;
}

/* ===//end of Pricacy-policy and Terms  part === */


/* === ? css start === */
/* ===//end of ? part === */
/* ===//end of main-section part === */


/* === footer section start === */
.footer {
    background: rgba(0, 0, 0, 0.9);
    padding: 10px 80px;
    color: #fff;
  }
  
  .footer ul li a {
    font-size: 14px;
    display: block;
    color: #fff;
    margin: 5px;
  }
  /* ===//end of footer section === */



/* ====== Responsive css ====== */

@media(min-width: 300px) and (max-width: 474px) {
	.footer {
	  padding: 10px 15px;
	}
}
@media(max-width: 575px) {
	.pricacy-term h2 {
		font-size: 25px;
	}

	.inner-box h3 {
		font-size: 1.1rem;
	}

	.inner-box p {
		font-size: .8rem;
	}

	.pricacy-term .container {
		max-width: 90%;
	}
}


