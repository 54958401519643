
/* ================== main css start ================= */

/*=== advertisement part start ===*/
.advertisement2  {
    box-shadow: 0px 5px 12.09px 0.91px rgba(0, 0, 0, 0.29) !important;
    position: relative;
    z-index: 222;
    height: 200px;
    background: url(../images/banner.png) no-repeat center center/cover;
}
/*===//end of advertisement part ===*/

/* === header css start === */
.header2 {
	/* max-width: 960px; */
	width: 100%;
	position: sticky;
	top: 0;
	z-index: 999;
	box-shadow: 0 0 10px rgba(0,0,0,.1);
}

.header2 .home-btn {
	color: rgba(0,0,0,.5);
	/* border-color: rgba(0,0,0,.1); */
	border: 1px solid rgba(0, 0, 0, .1);
	padding: .5rem .75rem;
	font-size: 1.25rem;
	line-height: 1;
	display: block;
	border-radius: .25rem;
	cursor: pointer;
	margin-right: 15px;
}

.header2 .home-btn:hover {
	color: #0090E3;
}

.navbar-light .navbar-toggler {
	position: relative;
	z-index: 9999;
}


.navbar-brand {
	max-width: 180px;
}

.header2 .navbar-light .navbar-nav .nav-link {
	text-align: center;
	color: #333;
	border-bottom: 1px solid rgba(0, 0, 0, .1);
	padding-left: 5px;
}

.header2 .navbar-light .navbar-nav .nav-link:hover {
	color: #0090E3;
}

.header2 .container {
	background: transparent
}
/* ===//end of header css === */


/* === form section start ===*/

.main-content-bg2 {
    background: url(../images/score-setting/mian-content-bg.png) no-repeat center center/cover;
    padding-bottom: 1px;
}

.form2{
    background: #333;
    color: #fff;
    box-shadow: 0 15px 10px rgba(0,0,0,.3);
    padding: 30px;
    border-radius: 15px;
	margin-bottom: 80px;
}

.form-mar{
	margin-right: 15%;
	margin-left: 15%;
}


form h2{
    text-align: center;
    color: #fff;
    font-weight: bold;
    font-size: 36px;
    padding-bottom: 15px;
}

.pass-error,
.required {
    font-size: 16px;
    margin-bottom: 10px;
}

.form-group {
    margin-bottom: 0px;
}


form label {
    display: block;
    /* font-size: 18px; */
    white-space: nowrap;
    padding: 0 10px 0 0;
    margin: 0;
}

.form-control:focus,
.form-control {
    background: transparent;
    /* font-size: 18px; */
    border: none;
    color: #fff;
    height: auto;
    padding: 3px;
    margin: 0;
}

.form-control::placeholder {
	color: #f1f1f1;
}

.score-sheet-btn .btn,
.submit-btn .btn {
    font-size: 22px;
    font-weight: 700;
    background: #0587ef;
    color: #fff;
    width: 100%;
    display: block;
    text-align: center;
    padding: 8px 0;
    border-radius: 28px;
    box-shadow: 0 10px 15px rgba(0,0,0,.2);
	max-width: 250px;
	margin: 20px auto;
}


.score-sheet-btn .btn:hover,
.submit-btn .btn:hover {
    box-shadow: 0 0 15px rgba(0,0,0,.2);
}

/* ===//end of form section ===*/


/* === footer part start === */
.footer {
	background: #1f2223;
	min-height: 90px;
	text-align: center;
  }
  
  .footer a{
	font-size: 17px;
	color: #fff;
	display: block;
  }
  
  .footer .footer-link li{
	display: inline-block;
	padding-top: 15px;
  }
  /* ===//end of  footer part === */



/* responsive */

@media (max-width: 450px) {
	.header2 .home-btn,
	.navbar-toggler {
		font-size: 1rem;
	}

	.navbar-brand {
		width: 150px;
	}

	.form-mar{
		margin-left: 5%;
		margin-right: 5%;
	}

}


@media (max-width: 767px) {
	.footer .footer-link  {
		padding-bottom: 15px;
	}
}
