html {
	scroll-behavior: smooth;
}

a, a:hover {
	text-decoration: none;
}

.hbody {
	font-family: 'Nunito Sans', sans-serif;
	margin: auto;
	background: #f1f1f1;
}
  
.section {
	background: #fff;
}

.header {
	width: 100%;
	position: fixed;
	top: 0;
	left: 50%;
	transform: translateX(-50%);
	z-index: 999;
	box-shadow: 0 0 10px rgba(0,0,0,.1);
}

.header .navbar {
	position: relative;
	z-index: 999;
}

.blue {
	color: #00b6f1 !important;
}


/* ===== banner-slide part start ======= */
.slider {
	padding-top: 95px;
}

.slider .slide-item {
	padding: 30px 0px;
  }
  
  .slider .slide-item img {
	box-shadow: 0px 0px 7.2px 2.8px rgba(0, 0, 0, 0.35);
  }
  
  
  
  .prev-arrow ,
  .next-arrow {
    width: 40px;
    height: 40px;
    font-size: 20px;
    border-radius: 50%;
    color: rgba(0, 0, 0, 0.6);
    background: rgba(255, 255, 255, 0.3);
    position: absolute;
    cursor: pointer;
    display: grid;
    place-items: center;
    transition: all 0.2s linear;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.6);
}
   
  .prev-arrow:hover ,
  .next-arrow:hover {
	color: #141414;
	background: rgba(255, 255, 255, 0.8);
  }
  
  .prev-arrow{
	top: 50%;
	left: 0px;
	transform: translateY(-50%);
	z-index: 1;
  }
  .next-arrow{
	top: 50%;
	right: 0px;
	transform: translateY(-50%);
  }
  /* ===== end of banner-slide part ======= */
  

/*=== heading text start === */
.heading-text h1 {
	font-weight: 700;
	
}
/*=== end of heading text === */

.main {
	background: url(../images/banner2.png);
	background-size: cover;
	background-position: center;
	padding: 70px 0;
	text-align: center;
}


.main a {
	font-family: 'Nunito Sans', sans-serif;
	display: block;
	background: #0090E3;
	color: white;
	text-decoration: none !important;
	font-weight: bold;
	font-size: 23px;
	width: 300px;
	border-radius: 80px;
	padding: 9px 0;
	border: none;
	margin: 30px auto;
	text-align: center;
	box-shadow: -1px 9px 11px rgba(0, 0, 0, .5);
	opacity: 0.9;
}

.about_user {
	display: flex;
	padding: 60px 0 40px 0;
	align-items: center;
}

.user_img img {
	max-width: 115px;
}
.user_Details ul {
	display: flex;
	flex-wrap: wrap;
	margin: 0;
}

.user_Details li {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
    font-size: 15px;
    padding-right: 26px;
}

.fm_tabs {
	padding-bottom: 60px;
}

.nav-tabs .nav-item {
	margin-bottom: -0;
	flex: 0 0 33.33%;
	max-width: 33.33%;
	-ms-flex: 0 0 33.33%;
	text-align: center;
	font-size: 21px;
}
.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
	color: #00b6f1;

}
.nav-tabs {
	border-bottom: 2px solid #00b6f1;
}
.fm_tabs a {
	color: black;
}

.nav-tabs .nav-link {
	border: unset;
}


/* === navbar css start ===180=100 */
.navbar-brand {
	max-width: 180px;
}

.header .navbar-light .navbar-nav .nav-link {
	text-align: center;
	color: #333;
	border-bottom: 1px solid rgba(0, 0, 0, .1);
	padding-left: 5px;
}

.header .navbar-light .navbar-nav .nav-link:hover {
	color: #00b6f1;
}

.header .container {
	background: transparent;
}

/* ===//end of nvbar css === */

.llc_1 {
	-ms-flex: 0 0 115px;
	flex: 0 0 115px;
	max-width: 115px;
}
.llc_1 p {
	margin: 0;
	font-size: 20px;
	font-weight: 500;
}

.llc_2 {
	-ms-flex: 1 1 auto;
	flex: 1 1 auto;
	max-width: auto;
}
.loading_bar {
    padding: 5px 0;
    display: flex;
    align-items: center;
}
.loading_bar_tt {
    background: #5d6a85;
    height: 13px;
    width: 100%;
    margin: 7px 0;
    border-radius: 0 30px 30px 0px;
}

.loading_bar_tt span {
	height: 100%;
	display: block;
	border-radius: 0 30px 30px 0px;
}

.tab-content>.active {
    display: block;
    padding: 20px 20px;
}


/* ===== footer part start ======= */
.footer {
	background: #1f2223;
	min-height: 90px;
	text-align: center;
  }
  
  .footer a{
	font-size: 17px;
	color: #fff;
	display: block;
  }
  
  .footer .footer-link li{
	display: inline-block;
	padding-top: 15px;
  }
  /* =====//end of  footer part ===== */

.navbar-light .navbar-toggler {
	position: relative;
	z-index: 9999;
}


div#collapsibleNavbar {
    background: #000000c2;
    position: absolute;
    right: 0;
    top: 18px;
    padding: 45px 25px 20px 25px;
}

.navbar-toggler-activated {
	color: white !important;
	position: relative;
	z-index: 9999;
}

@media (max-width: 450px) {
	
.navbar-brand {
	max-width: 150px;
	}

	.user_Details li {
		-ms-flex: 0 0 100%;
		flex: 0 0 100%;
		max-width: 100%;
		font-size: 15px;
		padding-right: 26px;
	}
}

@media (max-width: 575px) {
	.heading-text h1 {
		font-size: 2rem	
}
}

@media (max-width: 991px) {
	.footer .footer-link  {
		padding-bottom: 15px;
	}
}